import BaseViewModel from '../../infraestructure/BaseViewModel';
export default class AuthorizationViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }

    doLogin(formData) {
        this.api.security.login(formData)
            .then(response => this.view.onLoginResponse(response))
            .catch(this.view.onError)
    }

    getCurrentSession() { 
        this.api.security.getCurrenSession()
            .then(response => this.view.onFinCurrentSessionResponse(response))
            .catch(this.view.onError)
    }
}