import BaseList from '../../infraestructure/BaseList'
import StaffViewModel from '../../../core/features/staff/StaffViewModel'

export default {
    extends: BaseList,
    data(){
        return {
            viewModel: null,
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-staff', this.onFilter)
        this.onMountedEvent('on-submited-staff', this.onSearch)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-staff', this.onFilter)
        this.onBeforeDestroyEvent('on-submited-staff', this.onSearch)
    },
    created() {
        this.viewModel = new StaffViewModel(this)
        this.onActive()
    },
    methods: { 
        onActive() {
            this.onSearch()
        },
        onFilter(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate(){
            this.item = this.viewModel.create({})
            this.modalConfig.isVisible = true
        }
    }
}