<script>
import ListStaffComponent from "../features/ListStaffComponent";
import ModalStaffView from "./ModalStaffView";
export default {
  components: {
    ModalStaffView,
  },
  extends: ListStaffComponent,
};
</script>
<template>
  <div class="wrapper-list">
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="8" align="left">
        <p class="mb-sm-0">
          Mostrar 1 a {{ pagination.limit }} de
          {{ pagination.totalItems }} registros
        </p>
      </el-col>
      <el-col :span="16" align="right">
        <el-button-group>
          <el-button size="small" type="primary" @click="onCreate">
            Agregar Personal
          </el-button>
          <el-dropdown size="medium" class="more-options-action">
            <el-button type="plain" size="small">
              <i class="uil-ellipsis-v" /> M&aacute;s Opciones
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="my-2">
                <el-button
                  class="w-100 text-start"
                  size="small"
                  @click="onShowImport"
                >
                  <i class="el-icon-upload2" /> Importar
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item class="my-2">
                <el-button
                  class="w-100 text-start"
                  size="small"
                  @click="onExport"
                >
                  <i class="el-icon-download" /> Exportar
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-table
          v-loading="isListLoading"
          :data="collection"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            property="profileNumber"
            label="No. Empleado"
            show-overflow-tooltip
            width="160"
            align="center"
          />
          <el-table-column
            label="Nombre"
            show-overflow-tooltip
            width="160"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.firstName }} {{ scope.row.lastName }}
            </template>
          </el-table-column>
          <el-table-column
            property="username"
            label="Usuario"
            show-overflow-tooltip
          />
          <el-table-column
            property="email"
            label="Correo"
            show-overflow-tooltip
          />
          <el-table-column property="role" label="Rol" show-overflow-tooltip />
          <el-table-column
            property="createdAt"
            label="F. Creación"
            show-overflow-tooltip
            width="140"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.createdAt">
                {{ scope.row.createdAt | formatDate }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            property="updatedAt"
            label="F. Actualización"
            show-overflow-tooltip
            width="140"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.updatedAt">
                {{ scope.row.updatedAt | formatDate }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Opciones"
            width="120"
            align="center"
            show-overflow-tooltip
          >
            <template #default="scope">
              <el-dropdown @command="onChangeOption">
                <el-button size="small">
                  <i class="el-icon-more" />
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :command="{ command: 'edit', item: scope.row }"
                    >
                      <i class="el-icon-edit" /> Mostrar
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="{ command: 'delete', item: scope.row }"
                    >
                      <i class="el-icon-delete" /> Eliminar
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="8" align="left">
        <p class="mb-sm-0">
          Mostrar 1 a {{ pagination.limit }} de
          {{ pagination.totalItems }} registros
        </p>
      </el-col>
      <el-col :span="16" align="right">
        <el-pagination
          :page-count="pagination.totalPages"
          layout="prev, pager, next"
          @current-change="onPageChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <modal-staff-view
      v-if="modalConfig.isVisible"
      :config="modalConfig"
      :form-data="item"
    />
  </div>
</template>
