import _ from 'lodash';
import BaseModule from '../../infraestructure/BaseModule'

export default {
    extends: BaseModule,
    data() {
        return {
            filtersSelected: {
                code: '',
                name: '',
                dateRange: '',
            }
        }
    },
    mounted() {
    },
    beforeDestroy() {

    },
    methods: {
        onFilter() {
            this.onNotifyEvent('on-filters-carriers', this.filters)
        },
        onCertificationExpirationDateChange(dateRange) {
            if (dateRange) {
                this.addFilter('StartDate', dateRange[0])
                this.addFilter('EndDate', dateRange[1])
            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
            this.onFilter();
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onFilter();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onFilter();
        }, 500),
    }
}