export default class Carrier {
  constructor(options, state) {
    Object.assign(this, options)
    if (state) {
      this.state = state
    }
  }

  toSend() {
    const fd = new FormData()
    this.bindPictureFiles(fd)
    this.bindData(fd)
    return fd
  }

  bindPictureFiles(formData) {
    if (this.certificationCtpatFile) {
      formData.append('certificationCtpatFile', this.certificationCtpatFile)
    }
  }

  bindData(formData) {
    formData.append('carrier', JSON.stringify({
      id: this.id,
      code: this.code,
      name: this.name,
      certificationCtpatExpirationDate: this.certificationCtpatExpirationDate,
      certificationCtpatFileUrl: this.certificationCtpatFileUrl,
      isActive: this.isActive
    }))
  }
}
