<script>
import {EventBus} from '../../core/helpers/EventsHelper'
import SecurityStorageHelper from '../../core/helpers/SecurityStorageHelper'

export default {
  methods: {
    
    onError(error) {
      console.log(error.response)
      this.isLoading = false
      if (error.response) {
        switch (error.response.status) {
          case 400:
          case 404:
          case 403:
            // Mensaje Warning de notificación
            if (error.response.data.message)
              this.notifyWarning(error.response.data.message);
            break;
          case 401:
              if (error.response.data.message)
                this.notifyWarning(error.response.data.message);
            break;
          case 500:
            if (error.response.data.message)
              this.notifyError(error.response.data.message);
            else if (error.response.statusText)
              this.notifyError(error.response.statusText);
            break;
          default:
            break;
        }
      }
    },
    isAuthenticated() {
      return SecurityStorageHelper.isAuthenticated()
    },
    setToken(token){
      SecurityStorageHelper.setToken(token)
    },
    removeToken() {
      SecurityStorageHelper.removeToken()
    },
    getPermissions(moduleName) {
      return moduleName
    },
    setSession(data){
      SecurityStorageHelper.setSession(data)
    },
    getSession(){
      return SecurityStorageHelper.getSession()
    },
    removeSession(){
      SecurityStorageHelper.removeSession()
    },
    onMountedEvent(featureName, actionMethod){
      EventBus.$on(featureName, actionMethod);
    },
    onBeforeDestroyEvent(featureName, actionMethod){
      EventBus.$off(featureName, actionMethod);
    },
    onNotifyEvent(featureName, data) {
      EventBus.$emit(featureName, data);
    },
    notifySuccess(message) {
      this.$notify({
        title: "CONFIRMACIÓN",
        message: message,
        type: "success",
      });
    },
    notifyWarning(message) {
      this.$notify({
        title: "ADVERTENCIA",
        message: message,
        type: "warning",
      });
    },
    notifyError(message) {
      this.$notify({
        title: "ERROR",
        message: message,
        type: "error",
      });
    },
  },
};
</script>
