import BaseViewModel from "../../infraestructure/BaseViewModel";
import Role from "./Role";

export default class RolesViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    create(data) {
        return new Role(data, "create");
    }

    edit(data) {
        return new Role(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.roles
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.roles
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.roles
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.roles
            .find(this.getQueryParameters(filters))
            .then((response) =>
                this.view.onSearchResponse(response.data, response.headers)
            )
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.roles
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.roles.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    bindFormView() {}

    //#region

    mapCollection(collection) {
        return collection.map((item) => new Role(item));
    }

    //#endregion

}