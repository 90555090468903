import _ from 'lodash';
import BaseModule from '../../infraestructure/BaseModule'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                name: '',
            },
            roles: [],
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
        onFilter() {
            this.onNotifyEvent('on-filters-roles', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            (text) ? this.addFilter('Name', text) : this.removeFilter('Name')
            this.onFilter();
        }, 500),
    }
}