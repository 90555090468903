<script>
import ModuleExportationComponent from "../features/ModuleExportationComponent";
import ListExportationsView from "./ListExportationsView.vue";

export default {
  components: {
    "list-exportations-view": ListExportationsView,
  },
  extends: ModuleExportationComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          Exportación / Registros de Exportaciones
        </h4>
      </div>
      <div class="page-centerheader">
        <el-row type="flex" class="row-bg wrapper-filters" :gutter="20">
          <el-col :span="8" align="center">
            <el-input
              type="text"
              placeholder="Buscar por folio"
              v-model="filtersSelected.consecutiveNumber"
              clearable
              size="small"
              v-on:input="onSearchByConsecutiveNumber"
            ></el-input>
          </el-col>
          <el-col :span="8" align="center">
            <el-input
              type="text"
              placeholder="Buscar por remisión"
              v-model="filtersSelected.referenceNumber"
              clearable
              size="small"
              v-on:input="onSearchByReferenceNumber"
            ></el-input>
          </el-col>
          <el-col :span="8" align="center">
            <el-date-picker
              v-model="filtersSelected.dateRange"
              type="daterange"
              range-separator="a"
              start-placeholder="F. Reporte Inicial"
              end-placeholder="F. Reporte Final"
              size="small"
              value-format="yyyy-MM-dd"
              @change="onRegisterDateChange"
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-drawer
          :visible.sync="isMoreFilterVisible"
          direction="rtl"
          size="20%"
        >
          <el-row type="flex" class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :span="24" align="center">
              <el-input
                type="text"
                placeholder="Buscar por Nombre Chofer"
                v-model="filtersSelected.vehicleDriverName"
                clearable
                size="small"
                v-on:input="onSearchByVehicleDriverName"
              ></el-input>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :span="24" align="center">
              <el-input
                type="text"
                placeholder="Placas de Vehículo"
                v-model="filtersSelected.vehiclePlate"
                clearable
                size="small"
                v-on:input="onSearchByVehiclePlate"
              ></el-input>
            </el-col>
            
          </el-row>
          <el-row type="flex" class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :span="24">
              <el-select
                v-model="filtersSelected.carrier"
                filterable
                remote
                placeholder="Buscar por transportista"
                :remote-method="onFindCarriers"
                size="small"
                @change="onCarrierChange"
                clearable
              >
                <el-option
                  v-for="item in carriers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-drawer>
      </div>
      <div class="page-rightheader">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="right">
            <!--Filtros y Btns -->
            <div class="btn-list">
              <el-button type="default" plain icon="fa fa-filter" size="small" @click="onMoreFilters">
                Más Filtros:
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!--End Page header-->
    <list-exportations-view />
  </div>
</template>
