export default class BaseModel {
    constructor() {
        this.id = null;
    }

    static UPDATE_STATUS = {
        ADDED: 1,
        UPDATED: 2,
        REMOVED: 3
    }
}