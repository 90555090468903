<script>
import ListPermissionsComponent from "../features/ListPermissionsComponent";

export default {
  components: {},
  extends: ListPermissionsComponent,
};
</script>

<template>
  <div class="list-wrapper">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-row :gutter="20">
          <el-col :span="24">
            <h3>Roles</h3>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <div style="margin: 15px 0;" />
            <div style="height:600px; overflow-y: scroll;">
              <el-radio-group
                v-model="checkedRole"
                @change="onChangeRoles"
              >
                <el-card
                  v-for="(item, index) in roles"
                  :key="index"
                >
                  <el-row :gutter="20">
                    <el-col
                      :span="18"
                      align="left"
                    >
                      <el-radio :label="item.id">
                        {{
                          item.name
                        }}
                      </el-radio>
                    </el-col>
                    <el-col :span="6">
                      <i class="el-icon-user-solid" />
                    </el-col>
                  </el-row>
                </el-card>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="20">
          <el-col :span="24">
            <h3>Modulos</h3>
          </el-col>
        </el-row>
        <el-row type="flex"
          class="row-bg"
          :gutter="20">
          <el-col :span="24">
            <div style="margin: 15px 0;" />
            <div style="height:600px; overflow-y: scroll;">
              <el-collapse v-for="(currentModule, index) in modules" :key="index" class="collapse-module-permissions">
                <el-collapse-item :name="currentModule.code">
                  <template slot="title">
                   {{currentModule.name}}
                  </template>
                  <el-row :gutter="20" style="padding:5px 10px;">
                    <el-col :span="8" style="padding:5px 10px;">
                      <el-switch
                        v-model="currentModule.checkAll"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="onCheckAllChange(currentModule)" :disabled="!checkedRole">
                      </el-switch>
                      Seleccionar Todo
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" style="padding:5px 10px;">
                    
                      
                    <el-col :span="8" v-for="(currentPermission, permissionIndex) in currentModule.permissions" :key="permissionIndex" style="padding:5px 10px;">
                      <el-switch
                        v-model="currentPermission.isChecked"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :disabled="!checkedRole"
                        @change="onCheckPermission">
                      </el-switch>
                      {{currentPermission.name}}
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
