import BaseApi from "../../infraestructure/BaseApi";

export default class SecurityApi extends BaseApi {
  constructor(client, endPoint) {
    super(client, endPoint);
  }

  login(formData) {
    return this.client.post(`${this.endPoint}/login`, formData)
  }

  getCurrenSession() {
    return this.client.get(`${this.endPoint}/current-user`)
  }

  getPermissions(criteria) {
    const parameters = (criteria) ? `?${criteria}` : ''
    return this.client.get(`${this.endPoint}/permissions${parameters}`)
  }

  getPermissionsByRole(roleId) {
    return this.client.get(`${this.endPoint}/permissions-by-role/${roleId}`)
  }

  updateRolePermissions(data) {
    return this.client.post(`${this.endPoint}/update-permissions`, data)
  }
}