import axios from 'axios'
import SecurityStorageHelper from '../helpers/SecurityStorageHelper'
axios.interceptors.request.use(function(config) {
    const token = SecurityStorageHelper.getToken();
    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

var port = 5000;
export default class ApiWrapper {

    constructor() {}

    get(url, config) {
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.get(url, config)
    }

    post(url, data, config) {
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.post(url, data, config)
    }

    put(url, data, config) {
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.put(url, data, config)
    }

    delete(url, config) {
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.delete(url, config)
    }
}