<script>
import ModulePermissionsComponent from "../features/ModulePermissionsComponent";
import ListPermissionsView from "./ListPermissionsView";

export default {
  components: {
    ListPermissionsView,
  },
  extends: ModulePermissionsComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          Autorización / Permisos
        </h4>
      </div>
      <div class="page-rightheader">
        <!--Filtros y Btns -->
      </div>
    </div>
    <!--End Page header-->
    <list-permissions-view />
  </div>
</template>
