import _ from 'lodash';
import BaseModule from '../../infraestructure/BaseModule'
import StaffViewModel from '../../../core/features/staff/StaffViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                userName: '',
                email: '',
                role: ''
            },
            roles: [],
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new StaffViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindList()
        },
        onFilter() {
            this.onNotifyEvent('on-filters-staff', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByEmployeeCode: _.debounce(function (text) {
            (text) ? this.addFilter('EmployeeCode', text) : this.removeFilter('EmployeeCode')
            this.onFilter();
        }, 500),
        onSearchByUsername: _.debounce(function (text) {
            (text) ? this.addFilter('Username', text) : this.removeFilter('Username')
            this.onFilter();
        }, 500),
        onSearchByEmail: _.debounce(function (text) {
            (text) ? this.addFilter('Email', text) : this.removeFilter('Email')
            this.onFilter();
        }, 500),
        onSearchByFullName: _.debounce(function (text) {
            (text) ? this.addFilter('FullName', text) : this.removeFilter('FullName')
            this.onFilter();
        }, 500),
        onRoleChange(roleId) {
            (roleId) ? this.addFilter('RoleId', roleId) : this.removeFilter('RoleId')
            this.onFilter();
        }
    }
}