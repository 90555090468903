import ApiContext from './ApiContext'

export default class BaseViewModel {
    constructor(view) {
        this.view = view
        this.api = ApiContext
    }

    getQueryParameters(filters) {
        return filters.map((item) => `${item.filterName}=${item.filterValue}`).join('&')
    }

    mapPdfFile(pdfObject, fileName) {
        const url = window.URL.createObjectURL(new Blob([pdfObject]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    downloadLinkFile(link) {
        var a = document.createElement('A');
        var filePath = link;
        a.href = filePath;
        a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
