import BaseList from '../../infraestructure/BaseList'
import ExportationsViewModel from '../../../core/features/exportations/ExportationsViewModel'

export default {
    extends: BaseList,
    data() {
        return {
            viewModel: null,
            itemSelected: null,
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-exportations', this.onFilter)
        this.onMountedEvent('on-submited-exportations', this.onSearch)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-exportations', this.onFilter)
        this.onBeforeDestroyEvent('on-submited-exportations', this.onSearch)
    },
    created() {
        this.viewModel = new ExportationsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.onSearch()
        },
        onFilter(filters) {
            this.filters = filters
            this.onSearch()
        },
        onChangeOption(event) {
            if (event.command === "edit") this.onEdit(event.item.id);
            if (event.command === "delete") this.onDelete(event.item.id);
            if (event.command === "export-pdf") this.onExportPdf(event.item.id);
            this.itemSelected = event.item;
        },
        onCreate() {
            this.item = this.viewModel.create({
                pictures: [],
            })
            this.modalConfig.isVisible = true
        },
        onExportPdf(id) {
            this.viewModel.exportPdf(id)
        },
        onExportPdfResponse(response) {
            this.viewModel.mapPdfFile(response, `reporte_exportacion_fol_${this.itemSelected.consecutiveNumber}`);
            this.notifySuccess("Reporte Generado.");
        }
    }
}