import BaseForm from '../../infraestructure/BaseForm'
import ExportationsViewModel from '../../../core/features/exportations/ExportationsViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            carriers: [],
            operators: [],
            exportationFileSteps: [],
            isBolFileLoading: false,
            isInvoiceFileLoading: false,
            pictureSelected: {
                index: null,
                name: null,
                file: null,
                fileExtension: null,
            },
            rules: {
                reportDate: [{
                    required: true,
                    message: 'F. Reporte requerido.',
                    trigger: 'blur'
                }],
                referenceNumber: [{
                    required: true,
                    message: 'Remisión requerido.',
                    trigger: 'blur'
                }],
                carrier: [{
                    required: true,
                    message: 'Linea Transportista requerido.',
                    trigger: 'blur'
                }],
                operator: [{
                    required: true,
                    message: 'Montacarguista requerido.',
                    trigger: 'blur'
                }],
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ExportationsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.config.isVisible = false
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-exportations', null)
        },
        onFindOperators(data) {
            this.operators = []
            if (data)
                this.viewModel.findOperators(`FullName=${data}`)
        },
        onFindOperatorsResponse(response) {
            this.operators = response.data
        },
        async onUploadBolFileChange(file) {
            const isPdf = file.raw.type === 'application/pdf';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isPdf) {
                this.$message.error('El Archivo debe estar en formato PDF!');
            }
            if (!isLt2M) {
                this.$message.error('El Archivo excede los 2MB!');
            }
            if (isPdf & isLt2M) {
                this.isBolFileLoading = true

                setTimeout(async () => {
                    this.formData.bolFile = file.raw
                    this.formData.bolFileUrl = await this.viewModel.convertFileToBase64(file.raw)
                    this.$forceUpdate()
                    this.isBolFileLoading = false
                }, 600)
            }
        },
        onDownloadBolFile(item) {
            this.isBolFileLoading = true
            setTimeout(() => {
                this.viewModel.downloadLinkFile(item.bolFileUrl)
                this.isBolFileLoading = false
            }, 600);
        },
        onRemoveBolFile(item) {
            this.$confirm("¿Desea eliminar el archivo seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.isBolFileLoading = true
                    setTimeout(() => {
                        item.bolFileUrl = null
                        this.isBolFileLoading = false
                    }, 600);
                })
                .catch(() => { });
        },
        async onUploadBolInvoiceChange(file) {
            const isPdf = file.raw.type === 'application/pdf';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isPdf) {
                this.$message.error('El Archivo debe estar en formato PDF!');
            }
            if (!isLt2M) {
                this.$message.error('El Archivo excede los 2MB!');
            }
            if (isPdf & isLt2M) {
                this.isInvoiceFileLoading = true
                setTimeout(async () => {
                    this.formData.invoiceFile = file.raw
                    this.formData.invoiceFileUrl = await this.viewModel.convertFileToBase64(file.raw)
                    this.$forceUpdate()
                    this.isInvoiceFileLoading = false
                }, 600);
            }
        },
        onDownloadInvoiceFile(item) {
            this.isInvoiceFileLoading = true
            setTimeout(() => {
                this.viewModel.downloadLinkFile(item.invoiceFileUrl)
                this.isInvoiceFileLoading = false
            }, 600);
        },
        onRemoveInvoiceFile(item) {
            this.$confirm("¿Desea eliminar el archivo seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.isInvoiceFileLoading = true
                    setTimeout(() => {
                        item.invoiceFileUrl = null
                        this.isInvoiceFileLoading = false
                    }, 600);

                })
                .catch(() => { });
        },
        onGetPictures() {
            return this.formData.getPictures();
        },
        onAddPicture() {
            this.formData.addPicture({
                picture: null,
                isPictureFileLoading: false,
                updateStatus: 1
            })
        },
        onEditPicture(index, item) {
            item.updateStatus = 2 // BaseModel.UPDATE_STATUS.UPDATED
            this.$forceUpdate()
        },
        onDeletePicture(index) {
            this.$confirm("¿Desea eliminar el documento seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    this.formData.removePicture(index)
                    this.$forceUpdate()
                })
                .catch(() => { });
        },
        onUploadPictureChange(file) {
            const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('El Archivo debe estar en formato JPG, JPEG o PNG!');
            }
            if (!isLt2M) {
                this.$message.error('El Archivo excede los 2MB!');
            }
            if (isJPG & isLt2M) {
                this.formData.pictures[this.pictureSelected.index].isPictureFileLoading = true
                setTimeout(() => {
                    this.pictureSelected.file = file.raw
                    this.pictureSelected.fileExtension = this.viewModel.getFileExtension(this.pictureSelected.file.name)
                    this.$set(this.formData.pictures[this.pictureSelected.index], 'picture', this.pictureSelected)
                    this.$forceUpdate()
                    this.formData.pictures[this.pictureSelected.index].isPictureFileLoading = false
                    this.pictureSelected = { index: null, name: null, file: null, fileExtension: null };
                }, 600);
            }
        },
        onSelectPicture(index) {
            this.pictureSelected.index = index
            this.pictureSelected.name = this.viewModel.getGuid()
        },
        onDownloadPictureFile(item) {
            item.isPictureFileLoading = true
            this.$forceUpdate()
            setTimeout(() => {
                this.viewModel.downloadLinkFile(item.fileUrl)
                item.isPictureFileLoading = false
                this.$forceUpdate()
            }, 600);
        },
        onRemovePictureFile(item) {
            this.$confirm("¿Desea eliminar el archivo seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    item.isPictureFileLoading = true
                    setTimeout(() => {
                        item.picture = null
                        item.fileUrl = null
                        item.isPictureFileLoading = false
                    }, 600);
                })
                .catch(() => { });
        }
    }
}