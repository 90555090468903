<script>
import ModalCarrierComponent from "../features/ModalCarrierComponent";
export default {
  name: "ModalCarrierView",
  extends: ModalCarrierComponent,
};
</script>

<template>
  <el-dialog
    title="Transportista"
    :visible.sync="config.isVisible"
    width="25%"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información de Transportista</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center" v-loading="isCertificationCtpatFileLoading">
            <el-form-item
              label="Archivo de Certificación CTPAT"
              prop="certificationCtpatFile"
            >
              <el-tooltip
                placement="top"
                v-if="!formData.certificationCtpatFileUrl"
              >
                <div slot="content">
                  Solo archivos pdf con un<br />tamaño menor a 2MB.
                </div>

                <el-upload
                  class="upload-demo"
                  ref="uploadCertificationCtpat"
                  action=""
                  :show-file-list="false"
                  :on-change="onUploadCertificationCtpatFileChange"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >Selecciona un archivo</el-button
                  >
                </el-upload>
              </el-tooltip>
              <div v-else>
                <el-button-group>
                  <el-button
                    type="button"
                    size="small"
                    @click="onDownloadCertificationCtpatFile(formData)"
                    v-if="formData.certificationCtpatFileUrl"
                    ><i class="el-icon-download"></i
                  ></el-button>
                  <el-button
                    type="button"
                    size="small"
                    @click="onRemoveCertificationCtpatFile(formData)"
                    :disabled="!formData.certificationCtpatFileUrl"
                    ><i class="el-icon-delete"></i
                  ></el-button>
                </el-button-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <el-form-item
              label="Fecha de Expiración Certificado CTPAT"
              prop="certificationCtpatExpirationDate"
            >
              <el-date-picker
                v-model="formData.certificationCtpatExpirationDate"
                type="date"
                placeholder="Seleccionar Fecha"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>