import EndPoints from './EndPoints';
import ApiWrapper from './ApiWrapper';


// API AUTHORIZATION && AUTHENTICATION
import UsersApi from '../features/users/UsersApi'
import RoleApi from '../features/roles/RoleApi'
import PermissionsApi from '../features/permissions/PermissionsApi'
// import AuthorizationApi from '../features/authorization/AuthorizationApi'
import SecurityApi from '../features/security/SecurityApi';

// EMPRESA
import StaffApi from '../features/staff/StaffApi';

//API EXPORTATIONS
import CarrierApi from '../features/carriers/CarrierApi';
import ExportationApi from '../features/exportations/ExportationApi';
import ExportationFileStepsApi from '../features/exportationFileSteps/ExportationFileStepsApi';


const apiWrapper = new ApiWrapper();

export default {
    // API AUTHORIZATION && AUTHENTICATION
    // authorization: new AuthorizationApi(apiWrapper, `${EndPoints.API}${EndPoints.AUTH}`),
    users: new UsersApi(apiWrapper, `${EndPoints.API}${EndPoints.USERS}`),
    roles: new RoleApi(apiWrapper, `${EndPoints.API}${EndPoints.ROLES}`),
    permissions: new PermissionsApi(apiWrapper, `${EndPoints.API}${EndPoints.PERMISSIONS}`),
    security: new SecurityApi(apiWrapper, `${EndPoints.API}${EndPoints.SECURITY}`),
    carriers: new CarrierApi(apiWrapper, `${EndPoints.API}${EndPoints.CARRIERS}`),
    exportations: new ExportationApi(apiWrapper, `${EndPoints.API}${EndPoints.EXPORTATIONS}`),
    staff: new StaffApi(apiWrapper, `${EndPoints.API}${EndPoints.STAFF}`),
    exportationFileSteps: new ExportationFileStepsApi(apiWrapper, `${EndPoints.API}${EndPoints.EXPORTATION_FILE_STEPS}`),
}