export default class Staff {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            profileNumber: this.profileNumber,
            firstName: this.firstName,
            lastName: this.lastName,
            username: this.username,
            email: this.email,
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
            isActive: this.isActive,
            roleId: (this.role) ? this.role.id : null,
        }
    }
}