import BaseViewModel from "../../infraestructure/BaseViewModel";
import FileHelper from '../../helpers/FileHelper'
import Carrier from "./Carrier";

export default class CarriersViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    create(data) {
        return new Carrier(data, "create");
    }

    edit(data) {
        return new Carrier(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.carriers
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.carriers
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.carriers
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.carriers
            .find(this.getQueryParameters(filters))
            .then((response) =>
                this.view.onSearchResponse(response.data, response.headers)
            )
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.carriers
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.carriers.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    bindForm(formData) {
        this.api.roles.find()
        .then((response) => {
            this.view.roles = response.data.data
            if (formData.state === "update") {
                // formData.roles ? [formData.roles] : [];
            }
        })
        
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new Carrier(item));
    }

    //#endregion

    //#region QUERIES

    //#endregion QUERIES

     // #region HELP
     getGuid() {
        return FileHelper.generateGuid()
    }

    getFileExtension(fileName) {
        return FileHelper.getExtension(fileName)
    }

    convertFileToBase64(file) {
        return FileHelper.toBase64(file);
    }
    //#endregion
}