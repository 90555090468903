<script>
import ModalExportationComponent from "../features/ModalExportationComponent";
export default {
  name: "ModalExportationView",
  extends: ModalExportationComponent,
};
</script>

<template>
  <el-dialog
    title="Exportación"
    :visible.sync="config.isVisible"
    width="75%"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información de Exportación</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="3">
            <el-form-item label="Folio" prop="consecutiveNumber">
              <el-input
                v-model="formData.consecutiveNumber"
                size="small"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="F. Reporte" prop="reportDate">
              <el-date-picker
                v-model="formData.reportDate"
                type="date"
                placeholder="Seleccionar"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Remisión" prop="referenceNumber">
              <el-input v-model="formData.referenceNumber" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Linea Transportista" prop="carrier">
              <el-select
                v-model="formData.carrier"
                filterable
                clearable
                placeholder="Buscar..."
                size="small"
              >
                <el-option
                  v-for="item in carriers"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Montacarguista" prop="operator">
              <el-select
                v-model="formData.operator"
                filterable
                clearable
                remote
                placeholder="Buscar..."
                :remote-method="onFindOperators"
                size="small"
              >
                <el-option
                  v-for="item in operators"
                  :key="item.id"
                  :label="item.firstName + ' ' + item.lastName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="8">
            <el-form-item
              label="Nombre de Chofer Transportista"
              prop="vehicleDriverName"
            >
              <el-input v-model="formData.vehicleDriverName" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Placas de Vehículo" prop="vehiclePlate">
              <el-input v-model="formData.vehiclePlate" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Sellos de Vehículo" prop="vehicleSeals">
              <el-input v-model="formData.vehicleSeals" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="6">
            <el-form-item label="Numero de Bol" prop="bolNumber">
              <el-input v-model="formData.bolNumber" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="6" align="center" v-loading="isBolFileLoading">
            <el-form-item label="Archivo de Bol" prop="bolFile">
              <el-tooltip placement="top" v-if="!formData.bolFileUrl">
                <div slot="content">
                  Solo archivos pdf con un<br />tamaño menor a 2MB.
                </div>

                <el-upload
                  class="upload-demo"
                  ref="uploadBol"
                  action=""
                  :show-file-list="false"
                  :on-change="onUploadBolFileChange"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >Selecciona un archivo</el-button
                  >
                </el-upload>
              </el-tooltip>
              <div v-else>
                <el-button-group>
                  <el-button
                    type="button"
                    size="small"
                    @click="onDownloadBolFile(formData)"
                    v-if="formData.bolFileUrl"
                    ><i class="el-icon-download"></i
                  ></el-button>
                  <el-button
                    type="button"
                    size="small"
                    @click="onRemoveBolFile(formData)"
                    :disabled="!formData.bolFileUrl"
                    ><i class="el-icon-delete"></i
                  ></el-button>
                </el-button-group>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Numero de Factura" prop="invoiceNumber">
              <el-input v-model="formData.invoiceNumber" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="6" align="center" v-loading="isInvoiceFileLoading">
            <el-form-item label="Archivo de Factura" prop="invoiceFile">
              <el-tooltip placement="top" v-if="!formData.invoiceFileUrl">
                <div slot="content">
                  Solo archivos pdf con un<br />tamaño menor a 2MB.
                </div>
                <el-upload
                  class="upload-demo"
                  ref="uploadInvoice"
                  action=""
                  :show-file-list="false"
                  :on-change="onUploadBolInvoiceChange"
                  :auto-upload="false"
                  :multiple="false"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >Selecciona un archivo</el-button
                  >
                </el-upload>
              </el-tooltip>
              <div v-else>
                <el-button-group>
                  <el-button
                    type="button"
                    size="small"
                    @click="onDownloadInvoiceFile(formData)"
                    v-if="formData.invoiceFileUrl"
                    ><i class="el-icon-download"></i
                  ></el-button>
                  <el-button
                    type="button"
                    size="small"
                    @click="onRemoveInvoiceFile(formData)"
                    :disabled="!formData.invoiceFileUrl"
                    ><i class="el-icon-delete"></i
                  ></el-button>
                </el-button-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-tabs type="border-card">
              <el-tab-pane label="Gelería">
                <span slot="label"><i class="el-icon-file"></i> Imagenes</span>
                <el-row type="flex" class="row-bg" :gutter="20">
                  <el-col :span="24" align="right"
                    ><el-button
                      type="primary"
                      size="small"
                      @click="onAddPicture"
                      ><i class="el-icon-circle-plus"></i> Agregar
                      Imagen</el-button
                    >
                  </el-col>
                </el-row>
                <el-row type="flex" class="row-bg" :gutter="20">
                  <el-table :data="onGetPictures()" style="width: 100%">
                    <el-table-column
                      label="Etapa de Carga"
                      width="180"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-select
                          v-model="scope.row.exportationFileStep"
                          placeholder="Seleccionar"
                          size="small"
                          :disabled="!scope.row.updateStatus"
                        >
                          <el-option
                            v-for="item in exportationFileSteps"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column label="Título">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.title"
                          size="small"
                          :disabled="!scope.row.updateStatus"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column label="Descripción">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.description"
                          size="small"
                          :disabled="!scope.row.updateStatus"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column label="Archivo" width="200" align="center">
                      <template slot-scope="scope">
                        <div v-loading="scope.row.isPictureFileLoading">
                          <el-tooltip
                            placement="top"
                            v-if="!scope.row.picture && !scope.row.fileUrl"
                          >
                            <div slot="content">
                              Solo archivos jpg/jpeg/png con un<br />tamaño
                              menor a 2MB.
                            </div>
                            <el-upload
                              class="upload-demo"
                              ref="upload"
                              action=""
                              :show-file-list="false"
                              :on-change="onUploadPictureChange"
                              :auto-upload="false"
                              :multiple="false"
                            >
                              <el-button
                                slot="trigger"
                                size="small"
                                type="primary"
                                @click="onSelectPicture(scope.$index)"
                                ><i class="el-icon-upload"></i> Selecciona un
                                archivo</el-button
                              >
                            </el-upload>
                          </el-tooltip>
                          <div v-else>
                            <el-button-group>
                              <el-button
                                type="button"
                                size="small"
                                @click="onDownloadPictureFile(scope.row)"
                                v-if="scope.row.fileUrl"
                                ><i class="el-icon-download"></i
                              ></el-button>
                              <el-button
                                type="button"
                                size="small"
                                @click="onRemovePictureFile(scope.row)"
                                :disabled="!scope.row.updateStatus"
                                ><i class="el-icon-delete"></i
                              ></el-button>
                            </el-button-group>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Opciones"
                      width="180"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-button-group>
                          <el-button
                            size="small"
                            @click="onEditPicture(scope.$index, scope.row)"
                            v-if="scope.row.id"
                            >Editar</el-button
                          >
                          <el-button
                            size="small"
                            type="danger"
                            @click="onDeletePicture(scope.$index)"
                            >Eliminar</el-button
                          >
                        </el-button-group>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
