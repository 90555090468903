import Vue from "vue";
import Router from "vue-router";
import SecurityStorageHelper from '../core/helpers/SecurityStorageHelper';
Vue.use(Router);

import NotFoundView from "../components/shared/views/NotFoundView.vue";
// HOME
import ModuleHomeView from '../components/home/views/ModuleHomeView'


// AUTHORIZATION
import ModuleRoleView from '../components/roles/views/ModuleRoleView'
import ModuleUserView from '../components/users/views/ModuleUserView'
import ModulePermissionsView from '../components/permissions/views/ModulePermissionsView'
import LoginView from '../components/authorization/views/LoginView'

// EMPRESA
import ModuleStaffView from '../components/staff/views/ModuleStaffView'

// EXPORTACIONES
import ModuleCarrierView from '../components/carriers/views/ModuleCarrierView'
import ModuleExportationView from '../components/exportations/views/ModuleExportationView'

// import { nextTick } from "vue/types/umd";


const routes = [
    {
        path: "/login",
        name: "LoginView",
        component: LoginView,
        meta: {
            auth: false
        }
    },
    {
        path: "/",
        name: "ModuleHomeView",
        component: ModuleHomeView,
        meta: {
            auth: true
        }
    },
    {
        path: "/autorizacion/permisos",
        name: "ModulePermissionsView",
        component: ModulePermissionsView,
        meta: {
            auth: true
        }
    },
    {
        path: '/autorizacion/roles',
        name: "ModuleRoleView",
        component: ModuleRoleView,
        meta: {
            auth: true
        }
    },
    {
        path: '/autorizacion/usuarios',
        name: "ModuleUserView",
        component: ModuleUserView,
        meta: {
            auth: true
        }
    },
    {
        path: '/empresa/personal',
        name: "ModuleStaffView",
        component: ModuleStaffView,
        meta: {
            auth: true
        }
    },
    {
        path: '/exportaciones/lineas-de-transportacion',
        name: "ModuleCarrierView",
        component: ModuleCarrierView,
        meta: {
            auth: true
        }
    },
    {
        path: '/exportaciones/registros-de-exportaciones',
        name: "ModuleExportationView",
        component: ModuleExportationView,
        meta: {
            auth: true
        }
    },
    {
        path: "*",
        name: "NotFoundView",
        component: NotFoundView,
        meta: {
            auth: false
        }
    }
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active",
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        if(SecurityStorageHelper.getSession()) {
            next();
        } else {
            next({name: 'LoginView'});
        }
    } else {
        if(to.name === 'LoginView') {
            if(SecurityStorageHelper.getSession()) {
                next({name: 'Home'});
            } else{
                next();
            }
        } else {
            next();
        }
    }
    
});

export default router;