<script>
import ModuleStaffComponent from "../features/ModuleStaffComponent";
import ListStaffView from "./ListStaffView.vue";

export default {
  components: {
    "list-staff-view": ListStaffView,
  },
  extends: ModuleStaffComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">Empresa / Personal</h4>
      </div>
      <div class="page-centerheader">
        <el-row type="flex" class="row-bg wrapper-filters" :gutter="20">
          <el-col :span="8" align="center">
            <el-input
              type="text"
              placeholder="Buscar por no. empleado"
              v-model="filtersSelected.employeeCode"
              clearable
              size="small"
              v-on:input="onSearchByEmployeeCode"
            ></el-input>
          </el-col>
          <el-col :span="8" align="center">
            <el-input
              type="text"
              placeholder="Buscar por usuario"
              v-model="filtersSelected.userName"
              clearable
              size="small"
              v-on:input="onSearchByUsername"
            ></el-input>
          </el-col>
          <el-col :span="8" align="center">
            <el-input
              type="text"
              placeholder="Buscar por correo"
              v-model="filtersSelected.email"
              clearable
              size="small"
              v-on:input="onSearchByEmail"
            ></el-input>
          </el-col>
        </el-row>
        <el-drawer
          :visible.sync="isMoreFilterVisible"
          direction="rtl"
          size="20%"
        >
          <el-row type="flex" class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :span="24">
              <el-select
                v-model="filtersSelected.role"
                filterable
                placeholder="Buscar por rol"
                size="small"
                @change="onRoleChange"
                clearable
              >
                <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg wrapper-more-filters" :gutter="20">
            <el-col :span="24" align="center">
              <el-input
                type="text"
                placeholder="Buscar por nombre"
                v-model="filtersSelected.fullName"
                clearable
                size="small"
                v-on:input="onSearchByFullName"
              ></el-input>
            </el-col>
          </el-row>
        </el-drawer>
      </div>
      <div class="page-rightheader">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="right">
            <!--Filtros y Btns -->
            <div class="btn-list">
              <el-button
                type="default"
                plain
                icon="fa fa-filter"
                size="small"
                @click="onMoreFilters"
              >
                Más Filtros:
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--End Page header-->
    <list-staff-view />
  </div>
</template>
