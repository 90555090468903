import BaseViewModel from "../../infraestructure/BaseViewModel";
import FileHelper from '../../helpers/FileHelper'
import Exportation from "./Exportation";

export default class ExportationsViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    create(data) {
        return new Exportation(data, "create");
    }

    edit(data) {
        return new Exportation(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.exportations
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        } else {
            this.api.exportations
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.exportations
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.exportations
            .find(this.getQueryParameters(filters))
            .then((response) =>
                this.view.onSearchResponse(response.data, response.headers)
            )
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.exportations
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }
    
    exportPdf(id) {
        this.api.exportations.exportPdf(id)
            .then((response) => this.view.onExportPdfResponse(response.data))
            .catch(this.view.onError);
    }

    import(file) {
        this.api.exportations.import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    bindForm(formData) {
        this.api.carriers.find()
            .then((response) => {
                this.view.carriers = response.data.data
                return this.api.exportationFileSteps.find()
            })
            .then((response) => {
                this.view.exportationFileSteps = response.data.data
                if (formData.state === "update") {
                    this.view.operators = (formData.operator) ? [formData.operator] : [];
                    
                    formData.pictures.forEach((item) => {
                        item.isPictureFileLoading = false
                    });
                }
            })
            .catch(this.view.onError);

    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new Exportation(item));
    }

    //#endregion

    //#region QUERIES

    findOperators(query) {
        this.api.staff.find(query)
            .then((response) => this.view.onFindOperatorsResponse(response.data))
            .catch(this.view.onError);
    }
    
    findCarriers(query) {
        this.api.carriers.find(query)
            .then((response) => this.view.onFindCarriersResponse(response.data))
            .catch(this.view.onError);
    }

    //#endregion QUERIES

    // #region HELP
    getGuid() {
        return FileHelper.generateGuid()
    }

    getFileExtension(fileName) {
        return FileHelper.getExtension(fileName)
    }

    convertFileToBase64(file) {
        return FileHelper.toBase64(file);
    }
    //#endregion
}