import BaseApi from "../../infraestructure/BaseApi";

export default class ExportationApi extends BaseApi {
  constructor(client, endPoint) {
    super(client, endPoint);
  }

  exportPdf(id) {
    return this.client.get(`${this.endPoint}/export-pdf/${id}`, {responseType: 'blob'});
  }
}