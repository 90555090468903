<script>
import ModalUserComponent from "../features/ModalUserComponent";
export default {
  name: "ModalUserComponent",
  extends: ModalUserComponent,
};
</script>

<template>
  <el-dialog
    title="User"
    :visible.sync="config.isVisible"
    width="41%"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información de Usuario</legend>
        <el-row type="flex" class="row-bg" :gutter="20" />
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="12">
            <el-form-item label="Rol de Usuario" prop="role">
              <el-select
                v-model="formData.role"
                placeholder="Seleccionar..."
                size="small"
                value-key="id"
              >
                <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Usuario" prop="username">
              <el-input v-model="formData.username" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Correo" prop="email">
              <el-input v-model="formData.email" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="12">
            <el-form-item label="Contraseña" prop="password">
              <el-input
                v-model="formData.password"
                size="small"
                show-password
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Confirmar Contraseña"
              prop="passwordConfirmation"
            >
              <el-input
                v-model="formData.passwordConfirmation"
                size="small"
                show-password
              />
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>