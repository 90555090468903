<script>
import MainMenuComponent from "../features/MainMenuComponent";

export default {
  name: "MainMenuView",
  mixins: [MainMenuComponent],
};
</script>

<template>
  <div
    v-if="isAuthenticated()"
    class="horizontal-main hor-menu clearfix"
  >
    <div class="horizontal-mainwrapper container clearfix">
      <nav class="horizontalMenu clearfix">
        <el-menu
          :default-active="activeLink"
          class="horizontalMenu-list"
          mode="horizontal"
          :router="true"
          menu-trigger="click"
          unique-opened
        >
          <el-menu-item
            index="/"
            aria-haspopup="true"
            class="sub-icon"
          >
            <svg
              class="hor-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"
              />
            </svg>
            Resumen
          </el-menu-item>
          <el-submenu
            index="1"
            aria-haspopup="true"
            class="sub-icon"
          >
            <template slot="title">
              <svg
                class="hor-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11 4h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6h-4v-4h4v4zM17 3c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM7 13c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"
                />
              </svg>
              Exportaciones
            </template>
            <el-menu-item-group>
              <el-menu-item index="/exportaciones/registros-de-exportaciones">
                Registros de Exportaciones
              </el-menu-item>
              <el-menu-item index="/exportaciones/lineas-de-transportacion">
                Lineas de Transportaciones
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu
            index="4"
            aria-haspopup="true"
            class="sub-icon"
          >
            <template slot="title">
              <svg
                class="hor-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M22 7.999a1 1 0 0 0-.516-.874l-9.022-5a1.003 1.003 0 0 0-.968 0l-8.978 4.96a1 1 0 0 0-.003 1.748l9.022 5.04a.995.995 0 0 0 .973.001l8.978-5A1 1 0 0 0 22 7.999zm-9.977 3.855L5.06 7.965l6.917-3.822 6.964 3.859-6.918 3.852z"
                />
                <path
                  d="M20.515 11.126 12 15.856l-8.515-4.73-.971 1.748 9 5a1 1 0 0 0 .971 0l9-5-.97-1.748z"
                />
                <path
                  d="M20.515 15.126 12 19.856l-8.515-4.73-.971 1.748 9 5a1 1 0 0 0 .971 0l9-5-.97-1.748z"
                />
              </svg>
              Empresa
            </template>
            <el-menu-item index="/empresa/personal">
              Personal
            </el-menu-item>
          </el-submenu>
          <el-submenu
            index="5"
            aria-haspopup="true"
            class="sub-icon"
          >
            <template slot="title">
              <svg
                class="hor-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M20 7h-4V4c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H4c-1.103 0-2 .897-2 2v9a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9c0-1.103-.897-2-2-2zM4 11h4v8H4v-8zm6-1V4h4v15h-4v-9zm10 9h-4V9h4v10z"
                />
              </svg>
              Autorización
            </template>
            <el-menu-item index="/autorizacion/usuarios">
              Usuarios
            </el-menu-item>
            <el-menu-item index="/autorizacion/roles">
              Roles
            </el-menu-item>
            <el-menu-item index="/autorizacion/permisos">
              Permisos
            </el-menu-item>
          </el-submenu>
          
        </el-menu>
      </nav>
      <!--Nav-->
    </div>
  </div>
</template>
