<script>
import BaseComponent from './BaseComponent'

export default {
    extends: BaseComponent,
    methods: {
        onCancel(){
            this.config.isVisible = false;
        },
        onSave(formData){
            this.viewModel.save(formData);
        }
    }
}
</script>