<template>
  <div class="page">
    <div class="page-main">
      <div class="hor-header header top-header" v-if="isAuthenticated()">
        <header-view />
      </div>
      <div class="sticky">
        <main-menu-view />
      </div>
      <div class="hor-content main-content">
        <!--Main content-->
        <div class="container">
          <router-view />
        </div>
      </div>
    </div>
    <footer class="footer">
      <footer-view />
    </footer>
  </div>
</template>

<script>
import BaseComponent from './components/infraestructure/BaseComponent.vue'
import HeaderView from "./components/shared/views/HeaderView.vue";
import MainMenuView from "./components/shared/views/MainMenuView.vue";
import FooterView from "./components/shared/views/FooterView.vue";
export default {
  name: "App",
  extends: BaseComponent,
  components: {
    HeaderView,
    MainMenuView,
    FooterView,
  },
};
</script>
