<script>
//import ModuleHomeComponent from "./ModuleHomeComponent";

export default {
  components: {},
  //extends: ModuleHomeComponent,
};
</script>

<template>
  <!--Page header-->
  <div class="page-header">
    <div class="page-leftheader">
      <h4 class="page-title mb-0 text-primary">
        Resumen
      </h4>
    </div>
    <div class="page-rightheader">
      <!--Filtros y Btns -->
    </div>
  </div>
  <!--End Page header-->
</template>