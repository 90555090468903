import BaseForm from '../../infraestructure/BaseForm'
import UsersViewModel from '../../../core/features/users/UsersViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            roles: [],
            rules:{
                role: [
                    { required: true, message: "Selecciona un Role", trigger: "change" }
                ],
                username: [
                    { required: true, message: "Usuario requerido.", trigger: "blur" },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    },
                ],
                email: [
                    { required: true, message: "Correo requerido." },
                    {
                        type: "email",
                        message: "Ingresar email valido",
                        trigger: "blur,change"
                    },
                ],
                password: [
                    {
                        required: this.isPasswordRequired(),
                        message: "Contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    },
                ],
                passwordConfirmation: [
                    {
                        required: this.isPasswordRequired(),
                        message: "Verifiación de contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Solo se aceptan valores alfabetico.",
                        trigger: "change"
                    },
                    { validator: this.isValidateCheckPass }
                ],
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new UsersViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        isPasswordRequired() {
            return this.updatePassword || !this.formData.id;
        },
        isValidateCheckPass(rule, value, callback) {
            this.viewModel.verifyConfirmationPassword(value, callback)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.config.isVisible = false
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-user', null)
        }
    }
}