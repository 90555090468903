import BaseForm from '../../infraestructure/BaseForm'
import CarriersViewModel from '../../../core/features/carriers/CarriersViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            isCertificationCtpatFileLoading: false,
            rules:{
                code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props:{
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CarriersViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            // this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName){
            this.$refs[formName].validate((isValid)=>{
                if(isValid){
                 this.onSave(this.formData)   
                 return true   
                }
                return false
            }) 
        },
        onSaveResponse(response) {
            this.config.isVisible = false
            this.notifySuccess(response.message)
            this.onNotifyEvent('on-submited-carriers', null)
        },
        async onUploadCertificationCtpatFileChange(file){
            const isPdf = file.raw.type === 'application/pdf';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isPdf) {
                this.$message.error('El Archivo debe estar en formato PDF!');
            }
            if (!isLt2M) {
                this.$message.error('El Archivo excede los 2MB!');
            }
            if (isPdf & isLt2M) {
                this.isCertificationCtpatFileLoading = true
                setTimeout(async () => {
                    this.formData.certificationCtpatFile = file.raw
                    this.formData.certificationCtpatFileUrl = await this.viewModel.convertFileToBase64(file.raw)
                    this.$forceUpdate()
                    this.isCertificationCtpatFileLoading = false
                }, 600);
            }
        },
        onDownloadCertificationCtpatFile(item){
            this.viewModel.downloadLinkFile(item.certificationCtpatFileUrl)
        },
        onRemoveCertificationCtpatFile(item) {
            this.$confirm("¿Desea eliminar el archivo seleccionado?", "Eliminar", {
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                type: "warning",
            })
                .then(() => {
                    item.certificationCtpatFileUrl = null
                })
                .catch(() => { });
        },
    }
}