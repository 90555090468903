import BaseModel from '../../infraestructure/BaseModel';
export default class Exportation {
  constructor(options, state) {
    Object.assign(this, options)
    if (state) {
      this.state = state
    }
  }

  toSend() {
    const fd = new FormData()
    this.bindPictureFiles(fd)
    this.bindData(fd)
    return fd
  }

  bindPictureFiles(formData) {
    this.pictures.forEach((item) => {
      if (item.picture && item.picture.file) {
        formData.append('pictureFiles', item.picture.file, item.picture.name)
      }
    });

    if (this.invoiceFile) {
      formData.append('invoiceFile', this.invoiceFile)
    }

    if (this.bolFile) {
      formData.append('bolFile', this.bolFile)
    }
  }

  bindData(formData) {
    formData.append('exportation', JSON.stringify({
      id: this.id,
      reportDate: this.reportDate,
      referenceNumber: this.referenceNumber,
      vehicleDriverName: this.vehicleDriverName,
      vehiclePlate: this.vehiclePlate,
      vehicleSeals: this.vehicleSeals,
      bolNumber: this.bolNumber,
      bolFileUrl: this.bolFileUrl,
      invoiceNumber: this.invoiceNumber,
      invoiceFileUrl: this.invoiceFileUrl,
      carrierId: (this.carrier) ? this.carrier.id : null,
      operatorId: (this.operator) ? this.operator.id : null,
      isActive: this.isActive,
      pictures: this.mapPictures(this.pictures)
    }))
  }

  mapPictures(collection) {
    return collection.filter((item) => (item.picture || item.updateStatus)).map((item) => {
      return {
        id: item.id,
        exportationFileStep: item.exportationFileStep,
        title: item.title,
        description: item.description,
        name: (item.picture) ? item.picture.name : null,
        fileExtension: (item.picture) ? item.picture.fileExtension : null,
        updateStatus: item.updateStatus
      }
    });
  }

  getPictures() {
    return this.pictures.filter((item) => (item.updateStatus !== BaseModel.UPDATE_STATUS.REMOVED));
  }

  addPicture(data) {
    this.pictures.push(data);
  }

  removePicture(index) {
    let item = this.pictures[index];
    if (item.id) {
      this.pictures[index].updateStatus = BaseModel.UPDATE_STATUS.REMOVED;
    } else {
      this.pictures.splice(index, 1);
    }

  }
}