<script>
import ModalRoleComponent from "../features/ModalRoleComponent";
export default {
  name: "ModalRoleComponent",
  extends: ModalRoleComponent,
};
</script>

<template>
  <el-dialog
    title="Role"
    :visible.sync="config.isVisible"
    width="20%"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      status-icon
      :label-position="'top'"
    >
      <fieldset>
        <legend>Información de Role</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" class="bg-cancel" @click="onCancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        class="btn-red"
        size="small"
        @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>