import BaseComponent from '../../infraestructure/BaseComponent'

export default {
    extends: BaseComponent,
    data() {
        return {
        };
    },
    created() {
        this.onActive()
    },
    mounted() {},
    methods: {
        onActive() {
            
        }
    }
}