export default {
    API: '/api',
    AUTH: '/auth',
    USERS: '/users',
    ROLES: '/roles',
    PERMISSIONS: '/permissions',
    SECURITY: '/security',
    ACCESS_CONTROLS: '/access-controls',
    ACCESS_CONTROL_CONFIGURATIONS: '/access-control-configurations',
    SUPPLIERS: '/suppliers',
    SUPPLIER_CONFIGURATIONS: '/supplier-configurations',
    SUPPLIER_EMPLOYEES: '/supplier-employees',
    PERSONAL_SAFETY_EQUIPMENT: '/personal-safety-equipments',
    TASK_CONFIGURATIONS: '/task-configurations',
    TASK_EVALUATIONS: '/task-evaluations',
    TASK_SCHEDULES: '/task-schedules',
    WORK_AREAS: '/work-areas',
    WORK_CENTERS: '/work-centers',
    USER_IDENTIFICATIONS: '/user-identifications',
    STAFF: '/staff',
    EXPORTATIONS: '/exportations',
    CARRIERS: '/carriers',
    EXPORTATION_FILE_STEPS: '/exportations-file-steps',
}