<script>
import ModuleCarrierComponent from "../features/ModuleCarrierComponent";
import ListCarriersView from "./ListCarriersView.vue";

export default {
  components: {
    "list-carriers-view": ListCarriersView,
  },
  extends: ModuleCarrierComponent,
};
</script>

<template>
  <div class="module-wrapper">
    <!--Page header-->
    <div class="page-header">
      <div class="page-leftheader">
        <h4 class="page-title mb-0 text-primary">
          Exportaciones / Lineas de Transportistas
        </h4>
      </div>
      <div class="page-centerheader">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="5" align="center">
            <el-input
              type="text"
              placeholder="Buscar por código"
              v-model="filtersSelected.code"
              clearable
              size="small"
              v-on:input="onSearchByCode"
            ></el-input>
          </el-col>
          <el-col :span="9" align="center">
            <el-input
              type="text"
              placeholder="Buscar por nombre"
              v-model="filtersSelected.name"
              clearable
              size="small"
              v-on:input="onSearchByName"
            ></el-input>
          </el-col>
          <el-col :span="9" align="center">
            <el-date-picker
              v-model="filtersSelected.dateRange"
              type="daterange"
              range-separator="a"
              start-placeholder="F. Exp. Cert. Inicial"
              end-placeholder="F. Exp. Cert. Final"
              size="small"
              value-format="yyyy-MM-dd"
              @change="onCertificationExpirationDateChange"
            >
            </el-date-picker>
          </el-col>
        </el-row>
      </div>
      <div class="page-rightheader">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="right">
            <!--Filtros y Btns -->
            <div class="btn-list">
              <el-button type="default" plain icon="fa fa-filter" size="small">
                Más Filtros:
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--End Page header-->
    <list-carriers-view />
  </div>
</template>
