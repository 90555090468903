import _ from 'lodash';
import BaseModule from '../../infraestructure/BaseModule'
import ExportationsViewModel from '../../../core/features/exportations/ExportationsViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                consecutiveNumber: '',
                referenceNumber: '',
                dateRange: '',
                carrier: ''
            },
            carriers: [],
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    created() {
        this.viewModel = new ExportationsViewModel(this)
    },
    methods: {
        onFilter() {
            this.onNotifyEvent('on-filters-exportations', this.filters)
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onRegisterDateChange(dateRange) {
            if (dateRange) {
                this.addFilter('StartDate', dateRange[0])
                this.addFilter('EndDate', dateRange[1])
            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
            this.onFilter();
        },
        onSearchByConsecutiveNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('ConsecutiveNumber', text)
            } else {
                this.removeFilter('ConsecutiveNumber')
            }
            this.onFilter();
        }, 500),
        onSearchByReferenceNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('ReferenceNumber', text)
            } else {
                this.removeFilter('ReferenceNumber')
            }
            this.onFilter();
        }, 500),
        onSearchByVehicleDriverName: _.debounce(function (text) {
            (text) ? this.addFilter('VehicleDriverName', text) : this.removeFilter('VehicleDriverName')
            this.onFilter();
        }, 500),
        onSearchByVehiclePlate: _.debounce(function (text) {
            (text) ? this.addFilter('VehiclePlate', text) : this.removeFilter('VehiclePlate')
            this.onFilter();
        }, 500),
        onFindCarriers(data) {
            this.carriers = []
            if (data) {
                this.viewModel.findCarriers(`Name=${data}`)
            }
        },
        onFindCarriersResponse(response) {
            this.carriers = response.data
        },
        onCarrierChange(carrierId) {
            (carrierId) ? this.addFilter('CarrierId', carrierId) : this.removeFilter('CarrierId')
            this.onFilter();
        }
    }
}