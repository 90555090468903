import BaseList from '../../infraestructure/BaseList'
import CarriersViewModel from '../../../core/features/carriers/CarriersViewModel'

export default {
    extends: BaseList,
    data(){
        return {
            viewModel: null,
        }
    },
    mounted() {
        this.onMountedEvent('on-filters-carriers', this.onFilter)
        this.onMountedEvent('on-submited-carriers', this.onSearch)
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-filters-carriers', this.onFilter)
        this.onBeforeDestroyEvent('on-submited-carriers', this.onSearch)
    },
    created() {
        this.viewModel = new CarriersViewModel(this)
        this.onActive()
    },
    methods: { 
        onActive() {
            this.onSearch()
        },
        onFilter(filters) {
            this.filters = filters
            this.onSearch()
        },
        onCreate(){
            this.item = this.viewModel.create({})
            this.modalConfig.isVisible = true
        },
        onDownloadCertificationCtpatFile(item){
            this.isListLoading = true
            setTimeout(() => {
                this.viewModel.downloadLinkFile(item.certificationCtpatFileUrl)
                this.isListLoading = false
            }, 400);
        },
    }
}